<template>
  <div>
    <div class="form-group">
      <el-form-item label="Filled By">
        <el-select class="filledby" v-model="field.filled_by">
          <el-option
            v-for="(option, index) of getFilledByOptions"
            :key="index"
            :value="option.value"
            :label="option.name"
          ></el-option>
        </el-select>
      </el-form-item>
    </div>
  </div>
</template>

<script>
export default {
  name: "templates-formComponents-FieldFilledBy",
  props: ["field"],
  computed: {
    getFilledByOptions() {
      const options = [
        { name: "SENDER", value: "SENDER" },
        { name: "RECEIVER", value: "RECEIVER" },
        { name: "SENDER OR RECEIVER", value: "SENDER_OR_RECEIVER" },
      ];

      if (this.field.input_type === "SIGNATURE") {
        return options;
      }
      return this.field.input_type === "IMAGE" &&
        this.field.validations.multiple
        ? options.filter((option) => option.value !== "SENDER")
        : options;
    },
  },
  data() {
    return {
      fieldFilledByOptions: [
        { name: "SENDER", value: "SENDER" },
        { name: "RECEIVER", value: "RECEIVER" },
        { name: "SENDER OR RECEIVER", value: "SENDER_OR_RECEIVER" },
      ],
    };
  },
};
</script>

<style lang="scss">
.filledby {
}
</style>
